import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Loader from "../common/GlobalLoader";
import Select from "react-select";
import PremiumPackages from "../premium-packages/PremiumPackages";
import DPOConfig from "../json-editor/DPOConfig";

class EditOrganisationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleuserbyiddt: {},
      errors: {},
      ulogo: "",
      ubackgroundImg: "",
      ucountry: "",
      allcountryarrobjs: null,
      editorgloading: false,
      publisherZonesSelectOptions: [],
      defaultPaymentGatewayOptions: [
        { label: "Stripe", value: 1 },
        { label: "Remita", value: 2 },
        { label: "Dpo", value: 3 },
      ],
      chargesOptions: [],
      charges: [],
      overrideGatewayOptions: [
        { label: "Stripe", value: 1 },
        { label: "Remita", value: 2 },
        { label: "Dpo", value: 3 },
      ],
      collectAppPaymentOptions: [
        { label: "Hard Stop", value: 0 },
        { label: "Launch Payment Form", value: 1 },
        {
          label: "Launch LMS with parameters according to the specs",
          value: 2,
        },
      ],
      showRates: false,
      showGateways: false,
      showProductChargesSelect: true,
      ratesData: {
        productId: "",
        isoCountryCode: "",
        productName: "",
        currencyCode: "",
        term: "",
        rate: "",
        productDescription: "",
        productImage: "",
        taxRate: "",
        setDefault: false,
        taxInclusive: false,
        autoCollectTax: false,
        charges: [],
        tempCharges: [],
        displayRate: "",
        diasporaPlan: false,
      },
      gatewaysData: {
        overrideGateway: "",
        overrideCountries: "",
        gatewayName: "",
        gatewayInfo: "",
        gatewayImage: "",
        gatewayLink: "",
        dpoConfig: "",
      },
      ratesList: [],
      gatewaysList: [],
      councilProductImage: null,
      verificationFormList: [],
      verificationFormOptions: [
        { label: "verificationForm1", value: 1 },
        { label: "verificationForm2", value: 2 },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.onRateChange = this.onRateChange.bind(this);
    this.onProductImageChange = this.onProductImageChange.bind(this);
    this.onGatewayImageChange = this.onGatewayImageChange.bind(this);
    this.onGatewayChange = this.onGatewayChange.bind(this);
    this.onChangeImg = this.onChangeImg.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRatesSubmit = this.onRatesSubmit.bind(this);
    this.onGatewaysSubmit = this.onGatewaysSubmit.bind(this);
    this.resetGateway = this.resetGateway.bind(this);

    this.handleisCouncilCheck = this.handleisCouncilCheck.bind(this);
    this.handleSocialEnabledCheck = this.handleSocialEnabledCheck.bind(this);
    this.handleStrictModeCheck = this.handleStrictModeCheck.bind(this);
    this.handleisExamExempt = this.handleisExamExempt.bind(this);
    this.handleshowAccreditor = this.handleshowAccreditor.bind(this);
    this.handleshowFreeIcon = this.handleshowFreeIcon.bind(this);
    this.handleallowLiveEvents = this.handleallowLiveEvents.bind(this);
    this.handleconsolidateCertificates = this.handleconsolidateCertificates.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNTRIES}`)
      .then((res) => {
        this.setState({ allcountryarrobjs: res.data });
      })
      .catch((err) => {
        console.log("all country error is : " + err);
      });

    this.getCouncilById();
    this.getRatesByCouncilId();
    this.getGatewaysByCouncilId();
    this.loadPublisherZones();
    this.loadCharges();
    this.getVerificationFormList();
  }

  getVerificationFormList = () => {
    this.setState({ editorgloading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_VERIFICATION_FORMS}`)
      .then((res) => {
        let verificationFormOptions = [
          { value: "", label: "Select Verification Form" },
        ];

        verificationFormOptions = verificationFormOptions.concat(
          res.data.map((form) => {
            return { value: form.id, label: form.formName };
          })
        );

        this.setState({
          editorgloading: false,
          verificationFormList: res.data,
          verificationFormOptions,
        });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error is : " + err);
      });
  };

  loadCharges() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_CHARGES}`)
      .then((res) => {
        if (res.data) {
          let charges = this.getValidChargesForDropdown(res.data);
          this.setState({ chargesOptions: charges, charges: res.data });
        }
      })
      .catch((err) => {
        console.log("CHARGES API error: " + err);
      });
  }

  loadPublisherZones() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.PUBLISHER_ZONES}`)
      .then((res) => {
        this.setState({
          publisherZonesSelectOptions: this.getPublisherZonesSelectOptions(
            res.data
          ),
        });
      })
      .catch((err) => {
        console.log("PUBLISHER_ZONES API error: " + err);
      });
  }

  getValidChargesForDropdown(charges, ratesChargeIds = []) {
    if (!(charges || []).length) return [];

    charges = charges.filter(
      (d) =>
        d.chargeType != 1 &&
        (d.chargeCode || "").length != 0 &&
        !ratesChargeIds.includes(d.id)
    );
    return charges.map((d) => {
      return { label: d.chargeCode, value: d.id };
    });
  }

  getPublisherZonesSelectOptions(data) {
    try {
      return (data || []).map((d) => ({
        label: d.publisher,
        value: d.publisher,
      }));
    } catch (error) {
      return [];
    }
  }

  onChange(e) {
    const state = this.state.singleuserbyiddt;
    state[e.target.name] = e.target.value;
    this.setState({ singleuserbyiddt: state });
  }

  onRateChange(e) {
    const state = this.state.ratesData;
    state[e.target.name] = e.target.value;
    this.setState({ ratesData: state });
  }

  onGatewayImageChange(e) {
    let gatewayImageFormData = new FormData();
    gatewayImageFormData.append("files", e.target.files[0]);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_CONSTANTS.COUNCIL.UPLOADS_MULTIPLE}`, gatewayImageFormData)
      .then((res) => {
        if (res && res.data && res.data.length) {
          const state = this.state.gatewaysData;
          state["gatewayImage"] = res.data[0];
          this.setState({ gatewaysData: state });
        }
      })
      .catch((err) => {
        this.props.sendFlashMessage(
          "Something went wrong while uploading image!!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  }

  onProductImageChange(e) {
    let productImageFormData = new FormData();
    productImageFormData.append("files", e.target.files[0]);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_CONSTANTS.COUNCIL.UPLOADS_MULTIPLE}`, productImageFormData)
      .then((res) => {
        if (res && res.data && res.data.length) {
          this.setState({ councilProductImage: res.data[0] });
        }
      })
      .catch((err) => {
        this.props.sendFlashMessage(
          "Something went wrong while uploading image!!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  }

  onGatewayChange(e) {
    const state = this.state.gatewaysData;
    state[e.target.name] = e.target.value;
    this.setState({ gatewaysData: state });
  }

  handleOverrideGatewayChange = (event) => {
    const state = this.state.gatewaysData;
    state["overrideGateway"] = event.value;
    this.setState({ gatewaysData: state });
  };

  onChangeImg(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getCouncilById = () => {
    let id = this.props.match.params.id;
    console.log("param id is : " + id);
    this.setState({ editorgloading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_COUNCILS_BY_ID}/${id}`)
      .then((res) => {
        this.setState({ editorgloading: false });
        res.data.country = res.data.country.name;

        if (res.data && !res.data.advertisingPublisher) {
          res.data.advertisingPublisher = "default";
        }

        this.setState({ singleuserbyiddt: res.data });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error is : " + err);
      });
  };

  getRatesByCouncilId = () => {
    let id = this.props.match.params.id;
    this.setState({ editorgloading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_COUNCIL_RATES_BY_ID}/${id}`)
      .then((res) => {
        this.setState({ editorgloading: false });
        this.setState({ ratesList: res.data });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error ratesList is : " + err);
      });
  };
  fileInputRef = React.createRef();

  resetRates = (callback) => {
    this.setState(
      {
        ratesData: {
          productId: "",
          isoCountryCode: "",
          productName: "",
          currencyCode: "",
          term: "",
          rate: "",
          productDescription: "",
          productImage: "",
          taxRate: "",
          setDefault: false,
          taxInclusive: false,
          autoCollectTax: false,
          charges: [],
          selectCharges: [],
          tempCharges: [],
          displayRate: "",
          diasporaPlan: false,
        },
        councilProductImage: null,
      },
      () => callback && callback()
    );
    this.fileInputRef.current.value = null;
  };

  getGatewaysByCouncilId = () => {
    let id = this.props.match.params.id;
    this.setState({ editorgloading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_COUNCIL_GATEWAYS_BY_ID}/${id}`)
      .then((res) => {
        this.setState({ editorgloading: false });
        this.setState({ gatewaysList: res.data });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error gatewaysList is : " + err);
      });
  };
  fileInputGatewayRef = React.createRef();

  resetGateway(callback) {
    this.setState(
      {
        gatewaysData: {
          overrideGateway: "",
          overrideCountries: "",
          gatewayInfo: "",
          gatewayName: "",
          gatewayImage: "",
          gatewayLink: "",
          dpoConfig: "",
        },
      },
      () => callback && callback()
    );
    this.fileInputGatewayRef.current.value = null;
  }

  handleisCouncilCheck(e) {
    const state = this.state.singleuserbyiddt;
    state.council = !state.council;
    this.setState({ singleuserbyiddt: state });
  }
  handleSocialEnabledCheck(e) {
    const state = this.state.singleuserbyiddt;
    state.socialEnabled = !state.socialEnabled;
    this.setState({ singleuserbyiddt: state });
  }

  handleStrictModeCheck(e) {
    const state = this.state.singleuserbyiddt;
    state.strictMode = !state.strictMode;
    this.setState({ singleuserbyiddt: state });
  }

  handleisExamExempt(e) {
    const state = this.state.singleuserbyiddt;
    state.examExemptEnabled = !state.examExemptEnabled;
    this.setState({ singleuserbyiddt: state });
  }

  handleshowAccreditor(e) {
    const state = this.state.singleuserbyiddt;
    state.showAccreditor = !state.showAccreditor;
    this.setState({ singleuserbyiddt: state });
  }

  handleshowFreeIcon(e) {
    const state = this.state.singleuserbyiddt;
    state.showFreeIcon = !state.showFreeIcon;
    this.setState({ singleuserbyiddt: state });
  }

  handleallowLiveEvents(e) {
    const state = this.state.singleuserbyiddt;
    state.allowLiveEvents = !state.allowLiveEvents;
    this.setState({ singleuserbyiddt: state });
  }

  handleconsolidateCertificates(e) {
    const state = this.state.singleuserbyiddt;
    state.consolidateCertificates = !state.consolidateCertificates;
    this.setState({ singleuserbyiddt: state });
  }

  handlePublisherChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state["advertisingPublisher"] = event.value;
    this.setState({ singleuserbyiddt: state });
  };

  handleVerificationFormChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state["verificationFormId"] = event.value;

    this.setState({ singleuserbyiddt: state });
  };

  handleCollectAppPaymentChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state["collectAppPayment"] = event.value;
    state["stopMessage"] = "";

    this.setState({ singleuserbyiddt: state });
  };

  handleDefaultPaymentGatewayChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state["defaultPaymentGateway"] = event.value;

    this.setState({ singleuserbyiddt: state });
    if (event.value != 2) {
      const rateDataState = this.state.ratesData;
      rateDataState["rate"] = "";
      rateDataState["productDescription"] = "";
      rateDataState["productImage"] = "";
      rateDataState["taxRate"] = "";
      this.setState({ ratesData: rateDataState });
    }
  };

  onSubmit(e) {
    e.preventDefault();

    const { ulogo, ubackgroundImg } = this.state;

    const {
      logo,
      backgroundImg,
      councilName,
      council,
      socialEnabled,
      strictMode,
      examExemptEnabled,
      subdomain,
      lmsGroupId,
      showAdvertising,
      advertisingOnHomepage,
      searchPage,
      courseCompletion,
      courseRating,
      homepageAdPercentage,
      courseRatio,
      maxAdsPerDay,
      advertisingPublisher,
      collectAppPayment,
      stopMessage,
      defaultPaymentGateway,
      overrideGateway,
      overrideCountries,
      chargeable,
      demoCouncil,
      renewalDays,
      renewalText,
      isoCountry,
      verificationFormId,
      verificationDays,
      infoButtonUrl,
      gatewayInfo,
      showAccreditor,
      showFreeIcon,
      allowLiveEvents,
      consolidateCertificates,
    } = this.state.singleuserbyiddt;

    let finalcouncillogo;
    let finalbgImg;

    /* if (ulogo) {
      finalcouncillogo = ulogo.base64; //set it updated logo
    } else {
      finalcouncillogo = logo; //set it default back
    }

    if (ubackgroundImg) {
      finalbgImg = ubackgroundImg.base64; //set it updated bgImg
    } else {
      finalbgImg = backgroundImg; //set it default back
    }
    */
    if (ulogo) {
      finalcouncillogo = ulogo; //set it updated logo
    } else {
      finalcouncillogo = logo; //set it default back
    }

    if (ubackgroundImg) {
      finalbgImg = ubackgroundImg; //set it updated bgImg
    } else {
      finalbgImg = backgroundImg; //set it default back
    }

    // if(ucountry){
    //   finalcountry={
    //     id:ucountry.value,
    //     name:ucountry.label
    //   } //set it updated ucountry
    // }else{
    //   finalcountry={
    //     id:country.value,
    //     name:country.label
    //   } //set it default back
    // }

    // const OrganisationuserData = {
    //   councilName: councilName,
    //   council: council,
    //   firstname: firstName,
    //   lastname: lastName,
    //   subDomain: subdomain,
    //   email: email,
    //   logo: finalcouncillogo,
    //   backgroundImg: finalbgImg
    // };
    const OrganisationuserData = {
      councilName: councilName,
      council: council,
      socialEnabled: socialEnabled,
      strictMode: strictMode,
      examExemptEnabled:examExemptEnabled,
      subDomain: subdomain,
      logo: finalcouncillogo,
      backgroundImg: finalbgImg,
      lmsGroupId: parseInt(lmsGroupId),
      showAdvertising,
      advertisingOnHomepage,
      searchPage,
      courseCompletion,
      courseRating,
      homepageAdPercentage,
      courseRatio,
      maxAdsPerDay,
      advertisingPublisher,
      collectAppPayment,
      stopMessage,
      defaultPaymentGateway,
      overrideGateway,
      overrideCountries,
      chargeable,
      demoCouncil,
      renewalDays,
      renewalText,
      isoCountry,
      verificationFormId,
      verificationDays,
      infoButtonUrl,
      gatewayInfo,
      showAccreditor: showAccreditor !== undefined ? showAccreditor : false,
      showFreeIcon: showFreeIcon !== undefined ? showFreeIcon : false,
      allowLiveEvents: allowLiveEvents !== undefined ? allowLiveEvents : false,
      consolidateCertificates: consolidateCertificates !== undefined ? consolidateCertificates : false,
    };

    this.setState({ editorgloading: true });

    axios
      .put(
        `${API_CONSTANTS.COUNCIL.UPDATE_COUNCILS_BY_ID}/${this.props.match.params.id}`,
        OrganisationuserData
      )
      .then((res) => {
        this.props.sendFlashMessage(
          "Organisation User Updated successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ editorgloading: false, ulogo: "", ubackgroundImg: "" });
        this.getCouncilById();
        this.getRatesByCouncilId();
        this.getGatewaysByCouncilId();
      })
      .catch((err) => {
        this.setState({ editorgloading: false, ulogo: "", ubackgroundImg: "" });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });

    //  const paramid = this.props.match.params.id;
    // console.log(paramid);
    //  this.props.edituserinfo(invuserData, paramid, this.props.history);
  }

  onRatesSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ editorgloading: true });

    let ratesData = this.state.ratesData;
    let councilId = this.props.match.params.id;

    let data = {
      councilId,
      productId: ratesData.productId,
      isoCountryCodes: ratesData.isoCountryCode,
      productName: ratesData.productName,
      currencyCode: ratesData.currencyCode,
      term: Number(ratesData.term),
      rate: ratesData.rate,
      productDescription: ratesData.productDescription,
      productImage: this.state.councilProductImage,
      taxRate: ratesData.taxRate,
      defaultCouncilProduct: ratesData.setDefault,
      taxInclusive: ratesData.taxInclusive,
      autoCollectTax: ratesData.autoCollectTax,
      charges: ratesData.tempCharges,
      displayRate: ratesData.displayRate,
      diasporaPlan: ratesData.diasporaPlan,
    };
    if (ratesData.id) {
      // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_COUNCIL_RATES}/${ratesData.id}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Rates updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false });
          this.getRatesByCouncilId();
          this.getGatewaysByCouncilId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    } else {
      // NEW
      axios
        .post(`${API_CONSTANTS.COUNCIL.CREATE_COUNCIL_RATES}`, data)
        .then((res) => {
          this.props.sendFlashMessage(
            "Rates created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false });
          this.getRatesByCouncilId();
          this.resetRates();
          this.editCouncilRate(null, null, res.data);
          this.getGatewaysByCouncilId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    }
  }

  onGatewaysSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ editorgloading: true });

    let gatewaysData = this.state.gatewaysData;
    let councilId = this.props.match.params.id;

    let data = {
      councilId,
      overrideGateway: gatewaysData.overrideGateway,
      overrideCountries: gatewaysData.overrideCountries,
      gatewayName: gatewaysData.gatewayName,
      gatewayInfo: gatewaysData.gatewayInfo,
      gatewayImage: gatewaysData.gatewayImage,
      gatewayLink: gatewaysData.gatewayLink,
    };

    if (data.overrideGateway === 3) {
      data = {
        ...data,
        dpoConfig: gatewaysData.dpoConfig,
      };
    }
    if (gatewaysData.id) {
      // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_COUNCIL_GATEWAYS}/${gatewaysData.id}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Gateway updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.resetGateway();
          this.setState({ editorgloading: false });
          this.getGatewaysByCouncilId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    } else {
      // NEW
      axios
        .post(`${API_CONSTANTS.COUNCIL.CREATE_COUNCIL_GATEWAYS}`, data)
        .then((res) => {
          this.props.sendFlashMessage(
            "Gateways created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.resetGateway();
          this.setState({ editorgloading: false });
          this.getGatewaysByCouncilId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    }
  }

  // Callback~
  getFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      alert("Error: ulogo should be below 1 mb");
    } else {
      console.log("selected file is below :");

      this.setState({ ulogo: myfile });
    }
  }

  getbackgroundFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      alert("Error: background should be below 1 mb");
    } else {
      console.log("selected file is below :");

      this.setState({ ubackgroundImg: myfile });
    }
  }

  handlecountryChange = (country) => {
    this.setState({ ucountry: country });
  };

  handleAdvertisementCheckboxClick = (event) => {
    const state = this.state.singleuserbyiddt;
    state[event.target.name] = !state[event.target.name];
    this.setState({ singleuserbyiddt: state });
  };

  handleAdvertisementInputChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state[event.target.name] = event.target.value;
    this.setState({ singleuserbyiddt: state });
  };

  handleCheckboxClick = (e) => {
    const state = this.state.singleuserbyiddt;
    state[e.target.name] = !state[e.target.name];
    this.setState({ singleuserbyiddt: state });
  };

  handleRatesCheckboxClick = (e) => {
    const state = this.state.ratesData;
    state[e.target.name] = !state[e.target.name];
    this.setState({ ratesData: state });
  };

  handleInputChange = (event) => {
    const state = this.state.singleuserbyiddt;
    state[event.target.name] = event.target.value;
    this.setState({ singleuserbyiddt: state });
  };

  onNewChargeClick = (e) => {
    this.setState({ showProductChargesSelect: true });
  };

  onRatesCancelClick = (e) => {
    this.setState({
      ratesData: {
        productId: "",
        isoCountryCode: "",
        productName: "",
        currencyCode: "",
        term: "",
        rate: "",
        productDescription: "",
        productImage: "",
        taxRate: "",
        setDefault: false,
        taxInclusive: false,
        autoCollectTax: false,
        charges: [],
        selectCharges: [],
        tempCharges: [],
      },
    });
  };

  onGatewaysCancelClick = (e) => {
    this.setState({
      gatewaysData: {
        overrideGateway: "",
        overrideCountries: "",
        gatewayName: "",
        gatewayInfo: "",
        gatewayImage: "",
        gatewayLink: "",
        dpoConfig: "",
      },
    });
  };

  removeProductCharge = (rateCharges, chargeId, productId) => {
    axios
      .post(
        `${API_CONSTANTS.COUNCIL.DELETE_PRODUCT_CHARGE(productId, chargeId)}`
      )
      .then((res) => {
        let charges = this.getValidChargesForDropdown(
          this.state.charges,
          rateCharges
        );
        this.setState({ chargesOptions: charges });
        this.props.sendFlashMessage(
          "Product charge deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      })
      .catch((err) => {
        console.log("REMOVE PRODUCT CHARGE API error: " + err);
      });
  };

  onProductChargeDeleteClick = (e, chargeId, productId) => {
    let rateCharges = this.state.ratesData.tempCharges;

    const index = rateCharges.indexOf(chargeId);
    if (index > -1) rateCharges.splice(index, 1);

    this.removeProductCharge(rateCharges, chargeId, productId);
  };

  editCouncilRate = (e, rateId, rateObj = null) => {
    this.resetRates(() => {
      let rate =
        rateObj || this.state.ratesList.find((rate) => rate.id == rateId);
      if (rate && rate.id) {
        let data = {
          id: rate.id,
          councilId: rate.councilId,
          productId: rate.productId,
          isoCountryCode: rate.isoCountryCodes,
          productName: rate.productName,
          currencyCode: rate.currencyCode,
          term: rate.term,
          rate: rate.rate,
          productDescription: rate.productDescription,
          productImage: rate.productImage,
          taxRate: rate.taxRate,
          setDefault: rate.defaultCouncilProduct,
          taxInclusive: rate.taxInclusive,
          autoCollectTax: rate.autoCollectTax,
          charges: rate.charges,
          selectCharges: [],
          tempCharges: JSON.parse(JSON.stringify(rate.charges)),
          displayRate: rate.displayRate,
          diasporaPlan: rate.diasporaPlan,
        };

        let charges = this.getValidChargesForDropdown(
          this.state.charges,
          rate.charges
        );

        this.setState({
          ratesData: data,
          councilProductImage: rate.productImage,
          chargesOptions: charges,
        });
      }
    });
  };

  addNewRates = () => {
    this.resetRates();
  };

  deleteCouncilRate = (e, rateId) => {
    axios
      .get(`${API_CONSTANTS.COUNCIL.DELETE_COUNCIL_RATES(rateId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Rates deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ editorgloading: false });
        this.getRatesByCouncilId();
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  };

  editCouncilGateway = (e, gatewayId) => {
    this.resetGateway(() => {
      let gateway = this.state.gatewaysList.find(
        (gateway) => gateway.id == gatewayId
      );

      if (gateway && gateway.id) {
        let data = {
          id: gateway.id,
          councilId: gateway.councilId,
          overrideGateway: gateway.overrideGateway,
          overrideCountries: gateway.overrideCountries,
          gatewayName: gateway.gatewayName,
          gatewayInfo: gateway.gatewayInfo,
          gatewayImage: gateway.gatewayImage,
          gatewayLink: gateway.gatewayLink,
          dpoConfig: gateway.dpoConfig,
        };

        this.setState({ gatewaysData: data });
      }
    });
  };

  deleteCouncilGateway = (e, gatewayId) => {
    axios
      .get(`${API_CONSTANTS.COUNCIL.DELETE_COUNCIL_GATEWAYS(gatewayId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Gateway deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ editorgloading: false });
        this.getGatewaysByCouncilId();
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  };

  handleChargesChange = (e) => {
    const state = this.state.ratesData;
    state["tempCharges"] = [
      ...new Set(state["charges"].concat((e || []).map((x) => x["value"]))),
    ];
    state["selectCharges"] = e;
    this.setState({ ratesData: state });
  };

  render() {
    const {
      singleuserbyiddt,
      editorgloading,
      ulogo,
      ubackgroundImg,
      showRates,
      showGateways,
      ratesData,
      gatewaysData,
      ratesList,
      gatewaysList,
      councilProductImage,
      showProductChargesSelect,
      charges,
    } = this.state;
    // console.log(ratesData.selectCharges);
    // let countryoptions
    // let allcountryarrobjsloading
    // if (allcountryarrobjs === null) {
    //   allcountryarrobjsloading = "";
    // } else {
    //   if (allcountryarrobjs && Object.keys(allcountryarrobjs).length > 0) {
    //     countryoptions = allcountryarrobjs.map(w => ({
    //       label: w.name,
    //       value: w.id
    //     }));

    //     // console.log("country options type is : "+typeof countryoptions)
    //     // console.log(countryoptions)

    //   }
    // }

    return (
      <>
        <section class="wrapper">
          <Header />
          {/*<Sidebar activeStatus={"organisation"} />*/}
          <section class="">
            <Flashmessages />
            <div class="container-fluid">
              <div class="spacetb25">
                <nav class="breadcrumb__list">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      {" "}
                      <Link to="/organisation">Organisation</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Edit Organisation User
                    </li>
                  </ol>
                </nav>
                <div class="row">
                  <div class="col-xl-8 offset-xl-2">
                    <form
                      onSubmit={this.onSubmit}
                      encType="multipart/form-data"
                    >
                      <div class="card card__custom">
                        <div class="card-header">
                          <h5 class="card-title">*= Required Fields</h5>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Organisation Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputCouncil"
                                  placeholder="Enter organisation name"
                                  name="councilName"
                                  value={singleuserbyiddt.councilName || ""}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Email address</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputEmail1"
                                  placeholder="Enter email"
                                  name="email"
                                  value={singleuserbyiddt.email || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            {/*<div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputfirstName"
                                  placeholder="Enter first name"
                                  name="firstName"
                                  value={singleuserbyiddt.firstName || ""}
                                  disabled
                                />
                              </div>
                            </div>*/}
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Organisation Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputlastName"
                                  placeholder="Enter organisation code"
                                  name="lastName"
                                  value={singleuserbyiddt.lastName || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Username</label>
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputUsername"
                                  placeholder="Enter username"
                                  name="username"
                                  value={singleuserbyiddt.username || ""}
                                />
                              </div>
                            </div>
                            {/*<div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Subdomain</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputsubdomain"
                                  placeholder="Enter Subdomain"
                                  name="subdomain"
                                  value={singleuserbyiddt.subdomain || ""}
                                />
                              </div>
                            </div>*/}
                            {/*<div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Organisation Id</label>
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputorganisationId"
                                  placeholder="Enter organisation Id"
                                  name="organisationId"
                                  value={singleuserbyiddt.organisationId || ""}
                                />
                              </div>
                          </div>*/}
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Country</label>
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="exampleInputcountry"
                                  placeholder="Enter country"
                                  name="country"
                                  value={singleuserbyiddt.country || ""}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>LMS Group Id</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="lmsGroupId"
                                  min="0"
                                  placeholder="Enter Lms Group Id"
                                  name="lmsGroupId"
                                  value={singleuserbyiddt.lmsGroupId || ""}
                                />
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="council"
                                  onClick={(e) => this.handleisCouncilCheck(e)}
                                  checked={singleuserbyiddt.council}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  is council
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="socialEnabled"
                                  onClick={(e) =>
                                    this.handleSocialEnabledCheck(e)
                                  }
                                  checked={singleuserbyiddt.socialEnabled}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  is social
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="strictMode"
                                  onClick={(e) => this.handleStrictModeCheck(e)}
                                  checked={singleuserbyiddt.strictMode}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  is strict mode
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Organisation logo</label>
                                <input
                                  type="text"
                                  name="ulogo"
                                  className="form-control"
                                  onChange={this.onChangeImg}
                                  id="exampleInputulogo"
                                  placeholder="Enter s3 logo url"
                                  value={ulogo}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Organisation Background</label>
                                <input
                                  type="text"
                                  name="ubackgroundImg"
                                  className="form-control"
                                  onChange={this.onChangeImg}
                                  id="exampleInputubackgroundImg"
                                  placeholder="Enter s3 background Image url"
                                  value={ubackgroundImg}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Verification Form</label>
                                <Select
                                  value={
                                    this.state.verificationFormOptions.filter(
                                      (option) =>
                                        option.value ==
                                        singleuserbyiddt.verificationFormId
                                    ).length
                                      ? this.state.verificationFormOptions.filter(
                                          (option) =>
                                            option.value ==
                                            singleuserbyiddt.verificationFormId
                                        )
                                      : {
                                          value: "",
                                          label: "Select Verification Form",
                                        }
                                  }
                                  onChange={this.handleVerificationFormChange}
                                  options={this.state.verificationFormOptions}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Verification Days</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="verificationDays"
                                  placeholder="Enter Verification Days"
                                  name="verificationDays"
                                  value={
                                    singleuserbyiddt.verificationDays || ""
                                  }
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Info Button URL</label>
                                <input
                                  type="url"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="infoButtonUrl"
                                  placeholder="http://www.example.com"
                                  name="infoButtonUrl"
                                  value={singleuserbyiddt.infoButtonUrl || ""}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Gateway Info</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChange}
                                  id="gatewayInfo"
                                  placeholder="Enter Gateway Info"
                                  name="gatewayInfo"
                                  value={singleuserbyiddt.gatewayInfo || ""}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="examExemptEnabled"
                                  onClick={(e) => this.handleisExamExempt(e)}
                                  checked={singleuserbyiddt.examExemptEnabled}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  Exam Exempt
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="showAccreditor"
                                  onClick={(e) => this.handleshowAccreditor(e)}
                                  checked={singleuserbyiddt.showAccreditor}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  Show Accreditor
                                </span>
                              </div>
                            </div>


                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="showFreeIcon"
                                  onClick={(e) => this.handleshowFreeIcon(e)}
                                  checked={singleuserbyiddt.showFreeIcon}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  Show Free Icon
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="allowLiveEvents"
                                  onClick={(e) => this.handleallowLiveEvents(e)}
                                  checked={singleuserbyiddt.allowLiveEvents}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  Allow Live Events
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class={`form-group textbox__label`}>
                                <label>Optional</label>
                                <input
                                  type="checkbox"
                                  name="consolidateCertificates"
                                  onClick={(e) => this.handleconsolidateCertificates(e)}
                                  checked={singleuserbyiddt.consolidateCertificates}
                                />
                                <span
                                  style={{ color: "black", fontWeight: 700 }}
                                >
                                  {" "}
                                  Consolidate Certificates
                                </span>
                              </div>
                            </div>

                          </div>
                          <div class="show-advertisement">
                            <ul class="list-advertisement">
                              <li>
                                <div class="list-advert-left">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={singleuserbyiddt.showAdvertising}
                                      name="showAdvertising"
                                      onChange={
                                        this.handleAdvertisementCheckboxClick
                                      }
                                    />
                                    Show Advertising
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div class="list-advert-left">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={
                                        singleuserbyiddt.advertisingOnHomepage
                                      }
                                      name="advertisingOnHomepage"
                                      onChange={
                                        this.handleAdvertisementCheckboxClick
                                      }
                                    />
                                    Home Page
                                  </label>
                                </div>
                                <div class="list-advert-right">
                                  <div class="row">
                                    <div class="col-sm-4 text-left text-sm-right">
                                      <label class="text-label pd-t-5">
                                        %Ads
                                      </label>
                                    </div>
                                    <div class="col-sm-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value={
                                          singleuserbyiddt.homepageAdPercentage
                                        }
                                        name="homepageAdPercentage"
                                        onChange={
                                          this.handleAdvertisementInputChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="list-advert-left">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={singleuserbyiddt.searchPage}
                                      name="searchPage"
                                      onChange={
                                        this.handleAdvertisementCheckboxClick
                                      }
                                    />
                                    Search Page
                                  </label>
                                </div>
                                <div class="list-advert-right">
                                  <div class="row">
                                    <div class="col-sm-4 text-left text-sm-right">
                                      <label class="text-label pd-t-5">
                                        Course Ratio
                                      </label>
                                    </div>
                                    <div class="col-sm-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value={singleuserbyiddt.courseRatio}
                                        name="courseRatio"
                                        onChange={
                                          this.handleAdvertisementInputChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="list-advert-left">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={
                                        singleuserbyiddt.courseCompletion
                                      }
                                      name="courseCompletion"
                                      onChange={
                                        this.handleAdvertisementCheckboxClick
                                      }
                                    />
                                    Course Completion
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div class="list-advert-left">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={singleuserbyiddt.courseRating}
                                      name="courseRating"
                                      onChange={
                                        this.handleAdvertisementCheckboxClick
                                      }
                                    />
                                    Course Rating
                                  </label>
                                </div>
                              </li>
                            </ul>

                            <h5 class="mg-b-10 text-center">
                              <strong>Ad Spots</strong>
                            </h5>
                            <ul class="list-advertisement list-adds">
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Maximum Per Day
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={singleuserbyiddt.maxAdsPerDay}
                                      name="maxAdsPerDay"
                                      onChange={
                                        this.handleAdvertisementInputChange
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Publisher
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <Select
                                      value={{
                                        label:
                                          singleuserbyiddt.advertisingPublisher,
                                      }}
                                      onChange={this.handlePublisherChange}
                                      options={
                                        this.state.publisherZonesSelectOptions
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="show-advertisement pd-t-20 mg-t-20">
                            <h5 class="mg-b-20 text-center">
                              <strong>E-Commerce</strong>
                            </h5>
                            <ul class="list-advertisement list-adds">
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                                    &nbsp;
                                  </div>
                                  <div class="col-sm-6">
                                    <label class="custom-label-text">
                                      <input
                                        type="checkbox"
                                        checked={singleuserbyiddt.chargeable}
                                        name="chargeable"
                                        onChange={this.handleCheckboxClick}
                                      />
                                      Organisation Chargeable?
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Notification Days
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      value={singleuserbyiddt.renewalDays}
                                      name="renewalDays"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Renewal Text
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <textarea
                                      class="form-control"
                                      rows="4"
                                      value={singleuserbyiddt.renewalText}
                                      name="renewalText"
                                      onChange={this.handleInputChange}
                                    ></textarea>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      ISO_Country
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      value={singleuserbyiddt.isoCountry}
                                      name="isoCountry"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                                    &nbsp;
                                  </div>
                                  <div class="col-sm-6">
                                    <label class="custom-label-text">
                                      <input
                                        type="checkbox"
                                        checked={singleuserbyiddt.demoCouncil}
                                        name="demoCouncil"
                                        onChange={this.handleCheckboxClick}
                                      />
                                      Demo Mode
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Collect App Payment
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <Select
                                      value={this.state.collectAppPaymentOptions.filter(
                                        (option) =>
                                          option.value ==
                                          singleuserbyiddt.collectAppPayment
                                      )}
                                      onChange={
                                        this.handleCollectAppPaymentChange
                                      }
                                      options={
                                        this.state.collectAppPaymentOptions
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Stop Message
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      value={singleuserbyiddt.stopMessage}
                                      name="stopMessage"
                                      onChange={this.handleInputChange}
                                      disabled={
                                        singleuserbyiddt.collectAppPayment != 0
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Default Gateway
                                    </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <Select
                                      value={this.state.defaultPaymentGatewayOptions.filter(
                                        (option) =>
                                          option.value ==
                                          singleuserbyiddt.defaultPaymentGateway
                                      )}
                                      onChange={
                                        this.handleDefaultPaymentGatewayChange
                                      }
                                      options={
                                        this.state.defaultPaymentGatewayOptions
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                              {/* <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Override Gateway</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <Select
                                              value={ this.state.overrideGatewayOptions.filter(option => option.value == singleuserbyiddt.overrideGateway) }
                                              onChange={this.handleOverrideGatewayChange}
                                              options={this.state.overrideGatewayOptions}
                                            />
                                        </div>
                                    </div>
                                  </li>
                                  <li>
                                      <div class="row">
                                          <div class="col-sm-4 text-left text-sm-right">
                                              <label class="text-label pd-t-5">Override Countries</label>
                                          </div>
                                          <div class="col-sm-6">
                                              <input type="text" class="form-control" placeholder="" value={singleuserbyiddt.overrideCountries} name = "overrideCountries" onChange = {this.handleInputChange} />
                                          </div>
                                      </div>
                                  </li> */}
                              <li>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-toggle="modal"
                                    data-target="#premiumPackageModal"
                                  >
                                    Packages
                                  </button>
                                </div>
                              </li>
                              <li>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-toggle="modal"
                                    data-target="#rateCurrenicesModal"
                                  >
                                    Rates
                                  </button>
                                </div>
                              </li>
                              <li>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-toggle="modal"
                                    data-target="#gatewaysModal"
                                  >
                                    Gateways
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className={`show-advertisement pd-t-20 mg-t-20`}>
                            <h5 class="mg-b-20 text-center">
                              <strong>Rates Currencies</strong>
                            </h5>
                            <div class="table-box">
                              <div class="table-responsive">
                                <table
                                  class="table"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "15%" }}>
                                        Product ID
                                      </th>
                                      <th style={{ width: "15%" }}>
                                        ISO Country Code
                                      </th>
                                      <th style={{ width: "15%" }}>
                                        Product Name
                                      </th>
                                      <th style={{ width: "15%" }}>
                                        isDefault
                                      </th>
                                      <th style={{ width: "15%" }}>
                                        Currency Code
                                      </th>
                                      <th style={{ width: "15%" }}>Term</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ratesList.map((rate, i) => (
                                      <tr key={i}>
                                        <td>{rate.productId}</td>
                                        <td>{rate.isoCountryCodes}</td>
                                        <td>{rate.productName}</td>
                                        <td>
                                          {rate.defaultCouncilProduct.toString()}
                                        </td>
                                        <td>{rate.currencyCode}</td>
                                        <td>{rate.term}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className={`show-advertisement pd-t-20 mg-t-20`}>
                            <h5 class="mg-b-20 text-center">
                              <strong>Gateways</strong>
                            </h5>
                            <div class="table-box">
                              <div class="table-responsive">
                                <table
                                  class="table"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "25%" }}>
                                        Gateway Name
                                      </th>
                                      <th style={{ width: "25%" }}>
                                        Gateway Info
                                      </th>
                                      <th style={{ width: "25%" }}>
                                        Override Gateway
                                      </th>
                                      <th style={{ width: "25%" }}>
                                        Override Countries
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {gatewaysList.map((gateway, i) => (
                                      <tr key={i}>
                                        <td>{gateway.gatewayName}</td>
                                        <td>{gateway.gatewayInfo}</td>

                                        <td>
                                          {this.state.overrideGatewayOptions.find(
                                            (option) =>
                                              option.value ==
                                              gateway.overrideGateway
                                          ) &&
                                            this.state.overrideGatewayOptions.find(
                                              (option) =>
                                                option.value ==
                                                gateway.overrideGateway
                                            )["label"]}
                                        </td>
                                        <td>{gateway.overrideCountries}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-center">
                          <button type="submit" class="btn btn-secondary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Loader loadStatus={editorgloading} />
        </section>

        <div
          class="modal fade new__modal rate-currenices-modal"
          id="rateCurrenicesModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-xl our-custom-modal" role="document">
            <div class="modal-content">
              <div class="custom-modal-header">
                <div class="modal-header">
                  <h5 class="modal-title">Rate Currenices</h5>
                  <button
                    type="button"
                    onClick={() => this.resetRates()}
                    class="close closed__btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="icon-cross"></span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className={`show-advertisement pd-t-20 mg-t-20`}>
                  <h5 class="mg-b-20 text-center">
                    <strong>Rates Currencies</strong>
                  </h5>
                  <form onSubmit={this.onRatesSubmit}>
                    <ul class="list-advertisement list-adds">
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Product Id</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.productId}
                              name="productId"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              ISO_Country_Code
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.isoCountryCode}
                              name="isoCountryCode"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Product Name
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.productName}
                              name="productName"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.setDefault}
                                name="setDefault"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Is Default
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.autoCollectTax}
                                name="autoCollectTax"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Auto Collect Tax
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Currency Code
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.currencyCode}
                              name="currencyCode"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Term</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.term}
                              name="term"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Rate</label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.rate}
                                name="rate"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">
                                Product Description
                              </label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.productDescription}
                                name="productDescription"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">
                                Product Image
                              </label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="file"
                                id="exampleFormControlFile1"
                                name="newreconciliationfile"
                                onChange={this.onProductImageChange}
                                ref={this.fileInputRef}
                              />
                              {councilProductImage && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    height: "50%",
                                    width: "50%",
                                    objectFit: "cover",
                                  }}
                                >
                                  <img src={councilProductImage} />
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Tax Rate</label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.taxRate}
                                name="taxRate"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                              &nbsp;
                            </div>
                            <div class="col-sm-6">
                              <label class="custom-label-text">
                                <input
                                  type="checkbox"
                                  checked={ratesData.taxInclusive}
                                  name="taxInclusive"
                                  onChange={this.handleRatesCheckboxClick}
                                />
                                Tax Inclusive
                              </label>
                            </div>
                          </div>
                        </li>
                      )}
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Display Rate
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.displayRate}
                              name="displayRate"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.diasporaPlan}
                                name="diasporaPlan"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Diaspora Plan
                            </label>
                          </div>
                        </div>
                      </li>
                      {showProductChargesSelect && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Charges</label>
                            </div>
                            <div class="col-sm-6">
                              <Select
                                value={ratesData.selectCharges}
                                onChange={this.handleChargesChange}
                                options={this.state.chargesOptions}
                                isMulti={true}
                              />
                            </div>
                          </div>
                        </li>
                      )}

                      {ratesData && ratesData.id && (
                        <>
                          <li>
                            <div class="table-box">
                              <div class="table-responsive">
                                <table
                                  class="table"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "30%" }}>
                                        Charge Code
                                      </th>
                                      <th style={{ width: "30%" }}>
                                        Charge Description
                                      </th>
                                      <th style={{ width: "30%" }}>
                                        Charge Percentage
                                      </th>
                                      <th
                                        class="text-center"
                                        style={{ width: "10%" }}
                                      >
                                        Delete
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(ratesData.tempCharges || []).map(
                                      (id, i) => {
                                        let charge = charges.find(
                                          (c) => c.id == id
                                        );

                                        return (
                                          <tr key={i}>
                                            <td>{charge.chargeCode}</td>
                                            <td>{charge.chargeDescription}</td>
                                            <td>{charge.chargePercentage}</td>
                                            <td class="text-center">
                                              <a
                                                href="javascript:void(0)"
                                                class="btn btn-primary text-edit"
                                                onClick={(e) =>
                                                  this.onProductChargeDeleteClick(
                                                    e,
                                                    charge.id,
                                                    ratesData.id
                                                  )
                                                }
                                              >
                                                <span class="icon-cross clos-icon"></span>
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </li>
                        </>
                      )}

                      <li>
                        <div className="text-center">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={this.addNewRates}
                          >
                            Add New Rates
                          </button>
                          <button type="submit" class="btn btn-secondary ml-1">
                            Submit Rates
                          </button>
                        </div>
                      </li>
                    </ul>
                  </form>

                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style={{ minWidth: "900px" }}>
                        <thead>
                          <tr>
                            <th style={{ width: "12%" }}>Product ID</th>
                            <th style={{ width: "12%" }}>ISO Country Code</th>
                            <th style={{ width: "12%" }}>Product Name</th>
                            <th style={{ width: "13%" }}>isDefault</th>
                            <th style={{ width: "12%" }}>Currency Code</th>
                            <th style={{ width: "12%" }}>Term</th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Edit
                            </th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Del
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ratesList.map((rate, i) => (
                            <tr key={i}>
                              <td>{rate.productId}</td>
                              <td>{rate.isoCountryCodes}</td>
                              <td>{rate.productName}</td>
                              <td>{rate.defaultCouncilProduct.toString()}</td>
                              <td>{rate.currencyCode}</td>
                              <td>{rate.term}</td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.editCouncilRate(e, rate.id)
                                  }
                                >
                                  <span class="icon-edit"></span>
                                </a>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.deleteCouncilRate(e, rate.id)
                                  }
                                >
                                  <span class="icon-cross"></span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade new__modal rate-currenices-modal"
          id="gatewaysModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-xl our-custom-modal" role="document">
            <div class="modal-content">
              <div class="custom-modal-header">
                <div class="modal-header">
                  <h5 class="modal-title">Rate Currenices</h5>
                  <button
                    onClick={() => this.resetGateway()}
                    type="button"
                    class="close closed__btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="icon-cross"></span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className={`show-advertisement pd-t-20 mg-t-20`}>
                  <h5 class="mg-b-20 text-center">
                    <strong>Gateways</strong>
                  </h5>
                  <form onSubmit={this.onGatewaysSubmit}>
                    <ul class="list-advertisement list-adds">
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Gateway Name
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={gatewaysData.gatewayName}
                              name="gatewayName"
                              onChange={this.onGatewayChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Gateway Info
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={gatewaysData.gatewayInfo}
                              name="gatewayInfo"
                              onChange={this.onGatewayChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Override Gateway
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <Select
                              value={this.state.overrideGatewayOptions.filter(
                                (option) =>
                                  option.value == gatewaysData.overrideGateway
                              )}
                              onChange={this.handleOverrideGatewayChange}
                              options={this.state.overrideGatewayOptions}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Override Countries
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={gatewaysData.overrideCountries}
                              name="overrideCountries"
                              onChange={this.onGatewayChange}
                            />
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Gateway Image
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="file"
                              id="exampleFormControlFile1"
                              name="gatewayImage"
                              onChange={this.onGatewayImageChange}
                              ref={this.fileInputGatewayRef}
                            />
                            {gatewaysData.gatewayImage && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  height: "50%",
                                  width: "50%",
                                  objectFit: "cover",
                                }}
                              >
                                <img src={gatewaysData.gatewayImage} />
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Gateway Link
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="url"
                              class="form-control"
                              placeholder="https://example.com"
                              value={gatewaysData.gatewayLink}
                              name="gatewayLink"
                              onChange={this.onGatewayChange}
                            />
                          </div>
                        </div>
                      </li>
                      {this.state.gatewaysData.overrideGateway === 3 && (
                        <li>
                          <DPOConfig
                            value={gatewaysData.dpoConfig}
                            name="dpoConfig"
                            onChange={this.onGatewayChange}
                          />
                        </li>
                      )}

                      <li>
                        <div className="text-center">
                          <button type="submit" class="btn btn-secondary">
                            Submit Gateways
                          </button>
                        </div>
                      </li>
                    </ul>
                  </form>

                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style={{ minWidth: "900px" }}>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Gateway Name</th>
                            <th style={{ width: "20%" }}>Gateway Info</th>
                            <th style={{ width: "20%" }}>Override Gateway</th>
                            <th style={{ width: "20%" }}>Override Countries</th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Edit
                            </th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Del
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {gatewaysList.map((gateway, i) => (
                            <tr key={i}>
                              <td>{gateway.gatewayName}</td>
                              <td>{gateway.gatewayInfo}</td>
                              <td>
                                {this.state.overrideGatewayOptions.find(
                                  (option) =>
                                    option.value == gateway.overrideGateway
                                ) &&
                                  this.state.overrideGatewayOptions.find(
                                    (option) =>
                                      option.value == gateway.overrideGateway
                                  )["label"]}
                              </td>
                              <td>{gateway.overrideCountries}</td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.editCouncilGateway(e, gateway.id)
                                  }
                                >
                                  <span class="icon-edit"></span>
                                </a>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.deleteCouncilGateway(e, gateway.id)
                                  }
                                >
                                  <span class="icon-cross"></span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PremiumPackages
          auth={this.props.auth}
          sendFlashMessage={this.props.sendFlashMessage}
          clearcurrentFlashMessage={this.props.clearcurrentFlashMessage}
        />
      </>
    );
  }
}

EditOrganisationUser.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(EditOrganisationUser));
