import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import FileBase64 from "react-file-base64";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";

import Select from "react-select";
import Loader from "../common/GlobalLoader";

class AddCouncilUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      councilname: "",
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      councillogo: "",
      backgroundImg: "",
      subdomain: "",
      organisationId: "",
      country: "",
      isCouncil: false,
      examExemptEnabled: false,
      showAccreditor: false,
      showFreeIcon: false,
      allowLiveEvents: false,
      consolidateCertificates: false,
      socialEnabled: false,
      role: "wceaadmin",
      allcountryarrobjs: null,
      files: [],
      errors: {},
      formErrors: {
        councilname: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        country: "",
      },
      councilnameValid: false,
      firstNameValid: false,
      lastNameValid: false,
      subdomainValid: false,
      organisationIdValid: false,
      usernameValid: false,
      emailValid: false,
      passwordValid: false,
      countryValid: false,
      formValid: false,
      loading: false,
      showAdvertising: false,
      advertisingOnHomepage: false,
      searchPage: false,
      courseCompletion: false,
      courseRating: false,
      homepageAdPercentage: "",
      courseRatio: "",
      maxAdsPerDay: "",
      chargeable: false,
      demoCouncil: false,
      renewalDays: "",
      renewalText: "",
      isoCountry: "",
      advertisingPublisher: "",
      collectAppPayment: "",
      stopMessage: "",
      defaultPaymentGateway: "",
      overrideGateway: "",
      overrideCountries: "",
      verificationFormId: "",
      verificationDays: "",
      infoButtonUrl: "",
      gatewayInfo: "",
      publisherZonesSelectOptions: [],
      collectAppPaymentOptions: [
        { label: "Hard Stop", value: 0 },
        { label: "Launch Payment Form", value: 1 },
        {
          label: "Launch LMS with parameters according to the specs",
          value: 2,
        },
      ],
      defaultGatewayOptions: [
        { label: "Stripe", value: 1 },
        { label: "Remita", value: 2 },
        { label: "Dpo", value: 3 },
      ],
      overrideGatewayOptions: [
        { label: "Stripe", value: 1 },
        { label: "Remita", value: 2 },
        { label: "Dpo", value: 3 },
      ],
      verificationFormList: [],
      verificationFormOptions: [
        { label: "verificationForm1", value: 1 },
        { label: "verificationForm2", value: 2 },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNTRIES}`)
      .then((res) => {
        this.setState({ allcountryarrobjs: res.data });
      })
      .catch((err) => {
        console.log("all country error is : " + err);
      });

    this.loadPublisherZones();
    this.getVerificationFormList();

    /* var token = localStorage.getItem("jwtToken");
    //console.log("token is : "+token)
    var config = {
      headers: {'Authorization': `Bearer ${token}`}
  }; 
       axios.get("/api/groups",config).then(res => {
     
 this.setState({groupsall:res.data})
      }).catch(err=>{
        console.log("error is : "+err)
     //   alert("Something went wrong!!")
      })
*/
  }

  loadPublisherZones() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.PUBLISHER_ZONES}`)
      .then((res) => {
        this.setState({
          publisherZonesSelectOptions: this.getPublisherZonesSelectOptions(
            res.data
          ),
        });
      })
      .catch((err) => {
        console.log("PUBLISHER_ZONES API error: " + err);
      });
  }

  getVerificationFormList = () => {
    this.setState({ loading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_VERIFICATION_FORMS}`)
      .then((res) => {
        let verificationFormOptions = res.data.map((form) => {
          return { value: form.id, label: form.formName };
        });

        this.setState({
          loading: false,
          verificationFormList: res.data,
          verificationFormOptions,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("error is : " + err);
      });
  };

  getPublisherZonesSelectOptions(data) {
    try {
      return (data || []).map((d) => ({
        label: d.publisher,
        value: d.publisher,
      }));
    } catch (error) {
      return [];
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCollectAppPaymentChange = (event) => {
    let state = {};
    state["collectAppPayment"] = event.value;
    state["stopMessage"] = "";

    this.setState(state);
  };

  handleDefaultGatewayChange = (event) => {
    let state = {};
    state["defaultPaymentGateway"] = event.value;

    this.setState(state);
  };

  handleOverrideGatewayChange = (event) => {
    let state = {};
    state["overrideGateway"] = event.value;

    this.setState(state);
  };

  handleVerificationFormChange = (event) => {
    this.setState({ verificationFormId: event.value });
  };

  onSubmit(e) {
    e.preventDefault();
    const {
      councilname,
      firstName,
      lastName,
      email,
      username,
      password,
      councillogo,
      backgroundImg,
      subdomain,
      organisationId,
      country,
      isCouncil,
      examExemptEnabled,
      showAccreditor,
      showFreeIcon,
      allowLiveEvents,
      consolidateCertificates,
      socialEnabled,
      showAdvertising,
      advertisingOnHomepage,
      searchPage,
      courseCompletion,
      courseRating,
      homepageAdPercentage,
      courseRatio,
      maxAdsPerDay,
      chargeable,
      demoCouncil,
      renewalDays,
      renewalText,
      isoCountry,
      advertisingPublisher,
      collectAppPayment,
      stopMessage,
      defaultPaymentGateway,
      overrideGateway,
      overrideCountries,
      verificationFormId,
      verificationDays,
      infoButtonUrl,
      gatewayInfo,
    } = this.state;

    if (!councillogo) {
      this.props.sendFlashMessage(
        "Please upload organisation logo below 1 mb !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else if (!country) {
      this.props.sendFlashMessage("Please select country !!", "alert-danger");
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else if (!backgroundImg) {
      this.props.sendFlashMessage(
        "Please upload organisation background below 1 mb !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else {
      let councillogobase64 = councillogo.base64;
      let backgroundImgbase64 = backgroundImg.base64;

      let selectedcountry = {
        id: country.value,
        name: country.label,
      };

      const CounciluserData = {
        councilName: councilname,
        firstName: councilname,
        lastName: lastName,
        email: email,
        username: username,
        password: password,
        subdomain: subdomain,
        organisationId: organisationId,
        country: selectedcountry,
        council: isCouncil, //true or false
        examExemptEnabled: examExemptEnabled, //true or false
        showAccreditor : showAccreditor,
        showFreeIcon: showFreeIcon,
        allowLiveEvents: allowLiveEvents,
        consolidateCertificates:consolidateCertificates,
        socialEnabled: socialEnabled,
        logo: councillogobase64,
        backgroundImg: backgroundImgbase64,
        showAdvertising,
        advertisingOnHomepage,
        searchPage,
        courseCompletion,
        courseRating,
        homepageAdPercentage,
        courseRatio,
        maxAdsPerDay,
        chargeable,
        demoCouncil,
        renewalDays,
        renewalText,
        isoCountry,
        advertisingPublisher,
        collectAppPayment,
        stopMessage,
        defaultPaymentGateway,
        overrideGateway,
        overrideCountries,
        verificationFormId,
        verificationDays,
        infoButtonUrl,
        gatewayInfo,
      };
      console.log("received data is below : ");
      console.log(CounciluserData);
      console.log("councillogobase64 data is below : ");
      console.log(councillogobase64);
      console.log("backgroundImgbase64 data is below : ");
      console.log(backgroundImgbase64);

      this.setState({ loading: true });
      axios
        .post(`${API_CONSTANTS.COUNCIL.REGISTER}`, CounciluserData)
        .then((res) => {
          this.setState({
            councilname: "",
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            password: "",
            subdomain: "",
            organisationId: "",
            country: "",
            councillogo: "",
            backgroundImg: "",
            isCouncil: false,
            examExemptEnabled: false,
            showAccreditor: false,
            showFreeIcon: false,
            allowLiveEvents: false,
            consolidateCertificates: false,
            socialEnabled: false,
            loading: false,
            showAdvertising: false,
            advertisingOnHomepage: false,
            searchPage: false,
            courseCompletion: false,
            courseRating: false,
            homepageAdPercentage: "",
            courseRatio: "",
            maxAdsPerDay: "",
            advertisingPublisher: "",
            chargeable: false,
            demoCouncil: false,
            renewalDays: "",
            renewalText: "",
            isoCountry: "",
            overrideCountries: "",
            formErrors: {
              councilname: "",
              lastName: "",
              email: "",
              username: "",
              password: "",
              country: "",
            },
            verificationFormId: "",
            verificationDays: "",
            infoButtonUrl: "",
            gatewayInfo: "",
          });

          this.props.sendFlashMessage(
            "Organisation User Created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          // this.props.newLearnerSet()
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          console.log("error is : " + err);
        });
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    }); //here we set the state acc to thier name and value and also we are used callback for validate the field value during onchange
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let councilnameValid = this.state.councilnameValid;
    let lastNameValid = this.state.lastNameValid;
    let usernameValid = this.state.usernameValid;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let countryValid = this.state.countryValid;

    switch (fieldName) {
      case "councilname":
        councilnameValid = value.length > 0;
        fieldValidationErrors.councilname = councilnameValid ? "" : " is empty";
        break;
      case "lastName":
        lastNameValid = value.length > 0;
        fieldValidationErrors.lastName = lastNameValid ? "" : " is empty";
        break;
      case "username":
        usernameValid = value.length > 0;
        fieldValidationErrors.username = usernameValid ? "" : " is empty";
        break;
      case "country":
        countryValid = value.length > 0;
        fieldValidationErrors.country = countryValid ? "" : " is not selected";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        councilnameValid: councilnameValid,
        lastNameValid: lastNameValid,
        usernameValid: usernameValid,
        emailValid: emailValid,
        passwordValid: passwordValid,
        countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.lastNameValid &&
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.usernameValid &&
        this.state.councilnameValid &&
        this.state.countryValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  // Callback~
  getFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      //  alert("Error: logo should be below 1 mb")
      this.setState({ councillogo: "" });
      this.props.sendFlashMessage(
        "logo Image should be below 1 mb !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else {
      console.log("selected file is below :");
      console.log(myfile);

      this.setState({ councillogo: myfile });
    }
  }

  getbackgroundFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      //  alert("Error: background should be below 1 mb")

      this.setState({ backgroundImg: "" });
      this.props.sendFlashMessage(
        "Background Image should be below 1 mb !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else {
      console.log("selected file is below :");
      console.log(myfile);

      this.setState({ backgroundImg: myfile });
    }
  }

  handlecountryChange = (country) => {
    this.setState({ country });
    console.log(`Option selected:`, country);
  };

  handlePublisherChange = (event) => {
    this.setState({ advertisingPublisher: event.value });
  };

  handleisCouncilCheck = () => {
    this.setState({ isCouncil: !this.state.isCouncil });
    console.log("isCouncil value is : " + this.state.isCouncil);
  };

  handleisExamExempt = () => {
    this.setState({ examExemptEnabled: !this.state.examExemptEnabled });
    console.log("examExemptEnabled value is : " + this.state.examExemptEnabled);
  };

  handleshowAccreditor = () => {
    this.setState({ showAccreditor: !this.state.showAccreditor });
    console.log("examExemptEnabled value is : " + this.state.showAccreditor);
  };

  handleshowFreeIcon = () => {
    this.setState({ showFreeIcon: !this.state.showFreeIcon });
    console.log("showFreeIcon value is : " + this.state.showFreeIcon);
  };

  handleallowLiveEvents = () => {
    this.setState({ allowLiveEvents: !this.state.allowLiveEvents });
    console.log("allowLiveEvents value is : " + this.state.allowLiveEvents);
  };

  handleconsolidateCertificates = () => {
    this.setState({ consolidateCertificates: !this.state.consolidateCertificates });
    console.log("consolidateCertificates value is : " + this.state.consolidateCertificates);
  };

  handleSocialEnabledCheck = () => {
    this.setState({ socialEnabled: !this.state.socialEnabled });
    console.log("socialEnabled value is : " + this.state.socialEnabled);
  };

  handleAdvertisementCheckboxClick = (event) => {
    this.setState({ [event.target.name]: !this.state[event.target.name] });
  };

  handleAdvertisementInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxClick = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    //  const { isAuthenticated, user } = this.props.auth;

    const {
      councilname,
      email,
      firstName,
      lastName,
      username,
      password,
      subdomain,
      organisationId,
      country,
      allcountryarrobjs,
      loading,
    } = this.state;

    /* let groupData =   this.state.groupsall.map((data,index) => {
    return    <span style={{margin:10}}>

<input type="checkbox" name="group2" value={this.state.multiselectarr[index]} onClick={()=>this._handlegroupingevent(data.id,data.name)}/>{data.name} 

    </span>   
       
  })

      let finalgroupCont=(
        <div class="form-group col-md-4">
          {groupData}  
       </div>
      )*/
    let countryoptions;
    let allcountryarrobjsloading;
    if (allcountryarrobjs === null) {
      allcountryarrobjsloading = "";
    } else {
      if (allcountryarrobjs && Object.keys(allcountryarrobjs).length > 0) {
        countryoptions = allcountryarrobjs.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    return (
      <section class="wrapper">
        <Header />
        {/*} <Sidebar activeStatus={"organisation"} />*/}
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <nav class="breadcrumb__list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    {" "}
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Add Organisation User
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <form onSubmit={this.onSubmit} encType="multipart/form-data">
                    <div class="card card__custom">
                      <div class="card-header">
                        <h5 class="card-title">*= Required Fields</h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.councilname
                              )}`}
                            >
                              <label>Organisation Name</label>
                              <input
                                type="text"
                                class="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputCouncil"
                                placeholder="Enter organisation name"
                                name="councilname"
                                value={councilname}
                                required
                              />
                              {this.state.formErrors.councilname && (
                                <p style={{ color: "red" }}>
                                  Council Name{" "}
                                  {this.state.formErrors.councilname}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.email
                              )}`}
                            >
                              <label>Email address</label>
                              <input
                                type="email"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputEmail1"
                                placeholder="Enter email"
                                name="email"
                                value={email}
                                required
                              />
                              {this.state.formErrors.email && (
                                <p style={{ color: "red" }}>
                                  Email {this.state.formErrors.email}
                                </p>
                              )}
                            </div>
                          </div>
                          {/*}
                         <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.firstName
                              )}`}
                            >
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputfirstName"
                                placeholder="Enter first name"
                                name="firstName"
                                value={firstName}
                                required
                              />
                              {this.state.formErrors.firstName && (
                                <p style={{ color: "red" }}>
                                  First Name {this.state.formErrors.firstName}
                                </p>
                              )}
                            </div>
                          </div>
                         */}
                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.lastName
                              )}`}
                            >
                              <label>Organisation Code</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputlastName"
                                placeholder="Enter organisation code"
                                name="lastName"
                                value={lastName}
                                required
                              />
                              {this.state.formErrors.lastName && (
                                <p style={{ color: "red" }}>
                                  Last Name {this.state.formErrors.lastName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.username
                              )}`}
                            >
                              <label>Username</label>
                              <input
                                type="type"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputUsername"
                                placeholder="Enter username"
                                name="username"
                                value={username}
                                required
                              />
                              {this.state.formErrors.username && (
                                <p style={{ color: "red" }}>
                                  Username {this.state.formErrors.username}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.password
                              )}`}
                            >
                              <label>Password</label>
                              <input
                                type="password"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputPassword"
                                placeholder="Password"
                                name="password"
                                value={password}
                                required
                              />
                              {this.state.formErrors.password && (
                                <p style={{ color: "red" }}>
                                  Password {this.state.formErrors.password}
                                </p>
                              )}
                            </div>
                          </div>

                          {/*} <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.subdomain
                              )}`}
                            >
                              <label>Subdomain</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputsubdomain"
                                placeholder="Enter Subdomain"
                                name="subdomain"
                                value={subdomain}
                                required
                              />
                              {this.state.formErrors.subdomain && (
                                <p style={{ color: "red" }}>
                                  Subdomain {this.state.formErrors.subdomain}
                                </p>
                              )}
                            </div>
                          </div>
                      */}
                          {/*<div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.organisationId
                              )}`}
                            >
                              <label>Organisation Id</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="exampleInputorganisationId"
                                placeholder="Enter organisation Id"
                                name="organisationId"
                                value={organisationId}
                                required
                              />
                              {this.state.formErrors.organisationId && (
                                <p style={{ color: "red" }}>
                                  Organisation Id{" "}
                                  {this.state.formErrors.subdomain}
                                </p>
                              )}
                            </div>
                          </div>*/}

                          <div class="col-sm-6">
                            <div
                              class={`form-group textbox__label  ${this.errorClass(
                                this.state.formErrors.country
                              )}`}
                            >
                              <label>Country</label>
                              {allcountryarrobjsloading ? (
                                <span>country loading...</span>
                              ) : (
                                <Select
                                  value={country}
                                  onChange={this.handlecountryChange}
                                  options={countryoptions}
                                />
                              )}
                              {this.state.formErrors.country && (
                                <p style={{ color: "red" }}>
                                  Country {this.state.formErrors.country}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleisCouncilCheck}
                                checked={this.state.isCouncil}
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                is council
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleSocialEnabledCheck}
                                checked={this.state.socialEnabled}
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                is social
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Organisation logo</label>
                              <FileBase64
                                multiple={false}
                                onDone={this.getFiles.bind(this)}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Organisation Background</label>
                              <FileBase64
                                multiple={false}
                                onDone={this.getbackgroundFiles.bind(this)}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Verification Form</label>
                              <Select
                                value={this.state.verificationFormOptions.filter(
                                  (option) =>
                                    option.value ==
                                    this.state.verificationFormId
                                )}
                                onChange={this.handleVerificationFormChange}
                                options={this.state.verificationFormOptions}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Verification Days</label>
                              <input
                                type="type"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="verificationDays"
                                placeholder="Enter Verification days"
                                name="verificationDays"
                                value={this.state.verificationDays}
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Info Button URL</label>
                              <input
                                type="url"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="infoButtonUrl"
                                placeholder="http://www.example.com"
                                name="infoButtonUrl"
                                value={this.state.infoButtonUrl || ""}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Gateway Info</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInput}
                                id="gatewayInfo"
                                placeholder="Enter Gateway Info"
                                name="gatewayInfo"
                                value={this.state.gatewayInfo || ""}
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleisExamExempt}
                                checked={this.state.examExemptEnabled}
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                Exam Exempt
                              </span>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleshowAccreditor}
                                checked={this.state.showAccreditor}
                                name="showAccreditor"
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                Show Accreditor
                              </span>
                            </div>
                          </div>


                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleshowFreeIcon}
                                checked={this.state.showFreeIcon}
                                name="showFreeIcon"
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                Show Free Icon
                              </span>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleallowLiveEvents}
                                checked={this.state.allowLiveEvents}
                                name="allowLiveEvents"
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                Allow Live Events
                              </span>
                            </div>
                          </div>


                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Optional</label>
                              <input
                                type="checkbox"
                                onChange={this.handleconsolidateCertificates}
                                checked={this.state.consolidateCertificates}
                                name="consolidateCertificates"
                              />{" "}
                              <span style={{ color: "black", fontWeight: 700 }}>
                                Consolidate Certificates
                              </span>
                            </div>
                          </div>


                        </div>
                        <div class="show-advertisement">
                          <ul class="list-advertisement">
                            <li>
                              <div class="list-advert-left">
                                <label class="custom-label-text">
                                  <input
                                    type="checkbox"
                                    checked={this.state.showAdvertising}
                                    name="showAdvertising"
                                    onChange={
                                      this.handleAdvertisementCheckboxClick
                                    }
                                  />
                                  Show Advertising
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="list-advert-left">
                                <label class="custom-label-text">
                                  <input
                                    type="checkbox"
                                    checked={this.state.advertisingOnHomepage}
                                    name="advertisingOnHomepage"
                                    onChange={
                                      this.handleAdvertisementCheckboxClick
                                    }
                                  />
                                  Home Page
                                </label>
                              </div>
                              <div class="list-advert-right">
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      %Ads
                                    </label>
                                  </div>
                                  <div class="col-sm-8">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={this.state.homepageAdPercentage}
                                      name="homepageAdPercentage"
                                      onChange={
                                        this.handleAdvertisementInputChange
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="list-advert-left">
                                <label class="custom-label-text">
                                  <input
                                    type="checkbox"
                                    checked={this.state.searchPage}
                                    name="searchPage"
                                    onChange={
                                      this.handleAdvertisementCheckboxClick
                                    }
                                  />
                                  Search Page
                                </label>
                              </div>
                              <div class="list-advert-right">
                                <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">
                                      Course Ratio
                                    </label>
                                  </div>
                                  <div class="col-sm-8">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={this.state.courseRatio}
                                      name="courseRatio"
                                      onChange={
                                        this.handleAdvertisementInputChange
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="list-advert-left">
                                <label class="custom-label-text">
                                  <input
                                    type="checkbox"
                                    checked={this.state.courseCompletion}
                                    name="courseCompletion"
                                    onChange={
                                      this.handleAdvertisementCheckboxClick
                                    }
                                  />
                                  Course Completion
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="list-advert-left">
                                <label class="custom-label-text">
                                  <input
                                    type="checkbox"
                                    checked={this.state.courseRating}
                                    name="courseRating"
                                    onChange={
                                      this.handleAdvertisementCheckboxClick
                                    }
                                  />
                                  Course Rating
                                </label>
                              </div>
                            </li>
                          </ul>

                          <h5 class="mg-b-10 text-center">
                            <strong>Ad Spots</strong>
                          </h5>
                          <ul class="list-advertisement list-adds">
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Maximum Per Day
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={this.state.maxAdsPerDay}
                                    name="maxAdsPerDay"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Publisher
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <Select
                                    value={{
                                      label: this.state.advertisingPublisher,
                                    }}
                                    onChange={this.handlePublisherChange}
                                    options={
                                      this.state.publisherZonesSelectOptions
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="show-advertisement pd-t-20 mg-t-20">
                          <h5 class="mg-b-20 text-center">
                            <strong>E-Commerce</strong>
                          </h5>
                          <ul class="list-advertisement list-adds">
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                                  &nbsp;
                                </div>
                                <div class="col-sm-6">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={this.state.chargeable}
                                      name="chargeable"
                                      onChange={this.handleCheckboxClick}
                                    />
                                    Organisation Chargeable?
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Notification Days
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    value={this.state.renewalDays}
                                    name="renewalDays"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Renewal Text
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <textarea
                                    class="form-control"
                                    rows="4"
                                    value={this.state.renewalText}
                                    name="renewalText"
                                    onChange={this.handleInputChange}
                                  ></textarea>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    ISO_Country
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    value={this.state.isoCountry}
                                    name="isoCountry"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                                  &nbsp;
                                </div>
                                <div class="col-sm-6">
                                  <label class="custom-label-text">
                                    <input
                                      type="checkbox"
                                      checked={this.state.demoCouncil}
                                      name="demoCouncil"
                                      onChange={this.handleCheckboxClick}
                                    />
                                    Demo Mode
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Collect App Payment
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <Select
                                    value={this.state.collectAppPaymentOptions.filter(
                                      (option) =>
                                        option.value ==
                                        this.state.collectAppPayment
                                    )}
                                    onChange={
                                      this.handleCollectAppPaymentChange
                                    }
                                    options={
                                      this.state.collectAppPaymentOptions
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Stop Message
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    value={this.state.stopMessage}
                                    name="stopMessage"
                                    onChange={this.handleInputChange}
                                    disabled={this.state.collectAppPayment != 0}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                  <label class="text-label pd-t-5">
                                    Default Gateway
                                  </label>
                                </div>
                                <div class="col-sm-6">
                                  <Select
                                    value={this.state.defaultGatewayOptions.filter(
                                      (option) =>
                                        option.value ==
                                        this.state.defaultPaymentGateway
                                    )}
                                    onChange={this.handleDefaultGatewayChange}
                                    options={this.state.defaultGatewayOptions}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="card-footer text-center">
                        <button type="submit" class="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Loader loadStatus={loading} />
      </section>
    );
  }
}

AddCouncilUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(AddCouncilUser));
